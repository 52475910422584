import config from '../config/config'
import { axios } from './index'

const examMonitorUrl = config.baseUrl + '/exam/api/v1/monitor'

export default {
  //获取考试完整信息
  getDetail(examId) {
    return axios.get(`${examMonitorUrl}/detail/${examId}`)
  },
  //搜索评测监控列表
  searchExams(payload, pageNum, pageSize) {
    return axios.post(`${examMonitorUrl}/search/exams?page=${pageNum}&size=${pageSize}`, payload)
  },
  //增量获取正在考试的考试用户信息
  getOngoingUserInfo(examId, answerPaperId) {
    return axios.get(`${examMonitorUrl}/ongoing/user/info?examId=${examId}${answerPaperId ? '&answerPaperId=' + answerPaperId : ''}`)
  },
  //增量获取已提交的考试用户信息
  getSubmittedUserInfo(examId, lastAnswerPaperId) {
    return axios.get(`${examMonitorUrl}/submitted/user/info?examId=${examId}&lastAnswerPaperId=${lastAnswerPaperId}`)
  }
}
